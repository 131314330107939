var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "rounded-lg mb-50 mb-lg-1",
    attrs: {
      "header-bg-variant": "warning",
      "header-class": "py-25 px-1 rounded_header",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 d-flex-between text-white cursor-pointer",
          on: {
            "click": _vm.toggleVisibleTrips
          }
        }, [_c('span', {
          class: "fw-700 ".concat(_vm.isMobileView ? 'font-small-4' : '')
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.flight')) + " ")]), _c('div', {
          staticClass: "p-25"
        }, [_c('IconSvg', {
          staticClass: "py-25 rotate_class text-white",
          style: {
            transform: _vm.rotateStyleTripsAll,
            width: '18px'
          },
          attrs: {
            "src": require('@icons/triangle-down.svg'),
            "blank-color": "#ccc"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_vm._l(_vm.searchFlightArray, function (trip, tripIndex) {
    var _vm$resolveSelectedTr8, _vm$resolveSelectedTr9, _vm$resolveSelectedTr10, _vm$resolveSelectedTr11, _vm$resolveSelectedTr12, _vm$resolveSelectedTr13, _vm$resolveSelectedTr14, _vm$resolveSelectedTr15, _vm$resolveSelectedTr16, _vm$resolveSelectedTr17, _vm$resolveSelectedTr18, _vm$resolveSelectedTr19, _vm$resolveSelectedTr20, _vm$resolveSelectedTr21, _vm$resolveSelectedTr22, _vm$resolveSelectedTr23, _vm$resolveSelectedTr24, _vm$resolveSelectedTr25, _vm$resolveSelectedTr26, _vm$resolveSelectedTr27, _vm$resolveSelectedTr28, _vm$resolveSelectedTr29, _vm$resolveSelectedTr30, _vm$resolveSelectedTr31, _vm$resolveSelectedTr32, _vm$resolveSelectedTr33, _vm$resolveSelectedTr34, _vm$resolveSelectedTr35, _vm$resolveSelectedTr36, _vm$resolveSelectedTr37;
    return _c('div', {
      key: tripIndex,
      class: "cursor-pointer ".concat(tripIndex === _vm.tabIndex ? 'border-left-5-warning' : 'border-left-5-white'),
      staticStyle: {
        "border-bottom": "1px dashed #dddddd"
      }
    }, [_c('b-card', {
      staticClass: "mb-25",
      attrs: {
        "id": "tripItem-".concat(tripIndex),
        "header-class": "p-50",
        "body-class": "p-50",
        "no-body": ""
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          var _vm$resolveSelectedTr, _vm$resolveSelectedTr2, _vm$resolveSelectedTr3, _vm$resolveSelectedTr4, _vm$resolveSelectedTr5, _vm$resolveSelectedTr6, _vm$resolveSelectedTr7;
          return [_c('div', {
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return _vm.handleClickTab(tripIndex);
              }
            }
          }, [_c('div', {
            staticClass: "w-100 d-flex-between"
          }, [_c('div', {
            staticClass: "d-flex w-100"
          }, [_c('div', {
            staticClass: "mr-50 d-flex-center"
          }, [_c('b-avatar', {
            attrs: {
              "size": "27",
              "variant": tripIndex === _vm.tabIndex ? 'warning' : 'secondary'
            }
          }, [_vm._v(" " + _vm._s(tripIndex + 1) + " ")])], 1), _c('div', {
            staticClass: "d-flex-center justify-content-start w-100"
          }, [_c('div', {
            staticClass: "text-heading-4",
            class: tripIndex === _vm.tabIndex ? 'text-warning' : 'text-secondary',
            staticStyle: {
              "line-height": "24px",
              "font-weight": "800"
            }
          }, [_vm._v(" " + _vm._s(_vm.getAirportName(trip.startPoint)) + " ")]), _c('div', {
            staticClass: "d-flex-center"
          }, [_vm.isRTType ? _c('IAmIcon', {
            staticClass: "mx-25 d-flex-center",
            class: "".concat(tripIndex === _vm.tabIndex ? 'text-warning' : 'text-secondary'),
            attrs: {
              "icon": "swap",
              "size": "20px"
            }
          }) : _c('feather-icon', {
            class: "mx-25 ".concat(tripIndex === _vm.tabIndex ? 'text-warning' : 'text-secondary'),
            staticStyle: {
              "stroke-width": "3"
            },
            attrs: {
              "size": "20",
              "icon": "ArrowRightIcon"
            }
          })], 1), _c('div', {
            staticClass: "text-heading-4",
            class: tripIndex === _vm.tabIndex ? 'text-warning' : 'text-secondary',
            staticStyle: {
              "line-height": "24px",
              "font-weight": "800"
            }
          }, [_vm._v(" " + _vm._s(_vm.getAirportName(trip.endPoint)) + " ")])])])]), _c('div', {
            staticClass: "font-small-4 fw-800 ml-50 text-dark"
          }, [(_vm$resolveSelectedTr = _vm.resolveSelectedTrip) !== null && _vm$resolveSelectedTr !== void 0 && _vm$resolveSelectedTr.trips[tripIndex] ? _c('div', [_vm._v(" " + _vm._s(_vm.convertISODateTimeLangVN((_vm$resolveSelectedTr2 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr2 === void 0 ? void 0 : _vm$resolveSelectedTr2.trips[tripIndex].departure.at, (_vm$resolveSelectedTr3 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr3 === void 0 ? void 0 : _vm$resolveSelectedTr3.trips[tripIndex].departure.timezone).shortDay) + " "), _c('b-img', {
            staticClass: "py-0 px-50",
            attrs: {
              "blank-color": "#ccc",
              "src": require("@icons/column.svg")
            }
          }), _vm._v(" " + _vm._s(_vm.convertISODateTime((_vm$resolveSelectedTr4 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr4 === void 0 ? void 0 : _vm$resolveSelectedTr4.trips[tripIndex].departure.at, (_vm$resolveSelectedTr5 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr5 === void 0 ? void 0 : _vm$resolveSelectedTr5.trips[tripIndex].departure.timezone).time) + " - " + _vm._s(_vm.convertISODateTime((_vm$resolveSelectedTr6 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr6 === void 0 ? void 0 : _vm$resolveSelectedTr6.trips[tripIndex].arrival.at, (_vm$resolveSelectedTr7 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr7 === void 0 ? void 0 : _vm$resolveSelectedTr7.trips[tripIndex].arrival.timezone).time) + " ")], 1) : _c('div', {
            class: "".concat(tripIndex === _vm.tabIndex ? 'text-dark' : 'text-muted')
          }, [_vm._v(" " + _vm._s(_vm.convertISODateTimeLangVN(trip.departDate).shortDay) + " ")])])])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-collapse', {
      staticClass: "ml-75",
      attrs: {
        "id": "tripItem-".concat(tripIndex),
        "role": "tabpanel"
      },
      model: {
        value: _vm.isVisibleTrips[tripIndex],
        callback: function callback($$v) {
          _vm.$set(_vm.isVisibleTrips, tripIndex, $$v);
        },
        expression: "isVisibleTrips[tripIndex]"
      }
    }, [(_vm$resolveSelectedTr8 = _vm.resolveSelectedTrip) !== null && _vm$resolveSelectedTr8 !== void 0 && _vm$resolveSelectedTr8.trips[tripIndex] ? _c('div', [_c('div', {
      staticClass: "d-flex flex-wrap font-small-4 align-items-center fw-800"
    }, [_c('div', {
      staticClass: "text-nowrap",
      style: _vm.resolveColorFollowAirline((_vm$resolveSelectedTr9 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr9 === void 0 ? void 0 : _vm$resolveSelectedTr9.trips[tripIndex].airline)
    }, [_vm._v(" " + _vm._s("".concat(_vm.resolveAirlineFlightNumber((_vm$resolveSelectedTr10 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr10 === void 0 ? void 0 : (_vm$resolveSelectedTr11 = _vm$resolveSelectedTr10.trips[tripIndex]) === null || _vm$resolveSelectedTr11 === void 0 ? void 0 : _vm$resolveSelectedTr11.airline, ((_vm$resolveSelectedTr12 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr12 === void 0 ? void 0 : (_vm$resolveSelectedTr13 = _vm$resolveSelectedTr12.trips[tripIndex]) === null || _vm$resolveSelectedTr13 === void 0 ? void 0 : _vm$resolveSelectedTr13.flightNumber) || ((_vm$resolveSelectedTr14 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr14 === void 0 ? void 0 : (_vm$resolveSelectedTr15 = _vm$resolveSelectedTr14.trips[tripIndex]) === null || _vm$resolveSelectedTr15 === void 0 ? void 0 : (_vm$resolveSelectedTr16 = _vm$resolveSelectedTr15.segments[0]) === null || _vm$resolveSelectedTr16 === void 0 ? void 0 : _vm$resolveSelectedTr16.flightNumber)))) + " ")]), _c('b-img', {
      staticClass: "m-50",
      attrs: {
        "blank-color": "#ccc",
        "src": require("@icons/dot.svg")
      }
    }), _c('div', {
      staticClass: "text-nowrap"
    }, [_vm._v(" " + _vm._s(_vm.convertShortenAircraft((_vm$resolveSelectedTr17 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr17 === void 0 ? void 0 : (_vm$resolveSelectedTr18 = _vm$resolveSelectedTr17.trips[tripIndex]) === null || _vm$resolveSelectedTr18 === void 0 ? void 0 : (_vm$resolveSelectedTr19 = _vm$resolveSelectedTr18.segments[0]) === null || _vm$resolveSelectedTr19 === void 0 ? void 0 : _vm$resolveSelectedTr19.airCraft)) + " ")]), _c('b-img', {
      staticClass: "m-50",
      attrs: {
        "blank-color": "#ccc",
        "src": require("@icons/dot.svg")
      }
    }), _c('div', {
      staticClass: "text-nowrap text-truncate"
    }, [_vm._v(" " + _vm._s(((_vm$resolveSelectedTr20 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr20 === void 0 ? void 0 : (_vm$resolveSelectedTr21 = _vm$resolveSelectedTr20.trips[tripIndex]) === null || _vm$resolveSelectedTr21 === void 0 ? void 0 : _vm$resolveSelectedTr21.operator) === 'QH' ? (_vm$resolveSelectedTr22 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr22 === void 0 ? void 0 : (_vm$resolveSelectedTr23 = _vm$resolveSelectedTr22.trips[tripIndex]) === null || _vm$resolveSelectedTr23 === void 0 ? void 0 : (_vm$resolveSelectedTr24 = _vm$resolveSelectedTr23.fareOptions) === null || _vm$resolveSelectedTr24 === void 0 ? void 0 : _vm$resolveSelectedTr24.fareType : ((_vm$resolveSelectedTr25 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr25 === void 0 ? void 0 : (_vm$resolveSelectedTr26 = _vm$resolveSelectedTr25.trips[tripIndex]) === null || _vm$resolveSelectedTr26 === void 0 ? void 0 : (_vm$resolveSelectedTr27 = _vm$resolveSelectedTr26.fareOptions) === null || _vm$resolveSelectedTr27 === void 0 ? void 0 : _vm$resolveSelectedTr27.groupClass) || ((_vm$resolveSelectedTr28 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr28 === void 0 ? void 0 : (_vm$resolveSelectedTr29 = _vm$resolveSelectedTr28.trips[tripIndex]) === null || _vm$resolveSelectedTr29 === void 0 ? void 0 : _vm$resolveSelectedTr29.bookingClass)) + " ")]), _c('IAmLogoAirline', {
      attrs: {
        "airline": (['BL'].includes((_vm$resolveSelectedTr30 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr30 === void 0 ? void 0 : (_vm$resolveSelectedTr31 = _vm$resolveSelectedTr30.trips[tripIndex]) === null || _vm$resolveSelectedTr31 === void 0 ? void 0 : (_vm$resolveSelectedTr32 = _vm$resolveSelectedTr31.segments[0]) === null || _vm$resolveSelectedTr32 === void 0 ? void 0 : _vm$resolveSelectedTr32.operating) ? 'BL' : null) || ((_vm$resolveSelectedTr33 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr33 === void 0 ? void 0 : (_vm$resolveSelectedTr34 = _vm$resolveSelectedTr33.trips[tripIndex]) === null || _vm$resolveSelectedTr34 === void 0 ? void 0 : (_vm$resolveSelectedTr35 = _vm$resolveSelectedTr34.airline) === null || _vm$resolveSelectedTr35 === void 0 ? void 0 : _vm$resolveSelectedTr35.split(' | ')[0]) || ((_vm$resolveSelectedTr36 = _vm.resolveSelectedTrip) === null || _vm$resolveSelectedTr36 === void 0 ? void 0 : (_vm$resolveSelectedTr37 = _vm$resolveSelectedTr36.trips[tripIndex]) === null || _vm$resolveSelectedTr37 === void 0 ? void 0 : _vm$resolveSelectedTr37.operator),
        "custom-class": "mr-50 ml-auto px-25"
      }
    })], 1), !_vm.isCombination ? _c('div', [_c('AppCollapseItem', {
      staticClass: "mb-0",
      attrs: {
        "id": "priceItem-".concat(tripIndex),
        "is-visible": _vm.isVisiblePrice[tripIndex],
        "title": "",
        "class-header": "p-0",
        "class-body": "p-0",
        "no-body": ""
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "w-100 d-flex flex-nowrap align-items-center text-warning cursor-pointer mt-0 mb-50",
            staticStyle: {
              "max-width": "520px"
            },
            attrs: {
              "id": "priceItem-toggle-".concat(tripIndex)
            },
            on: {
              "click": function click($event) {
                return _vm.toggleVisiblePrice(tripIndex);
              }
            }
          }, [_vm.isVisiblePriceFareCol ? _c('div', {
            staticClass: "d-flex flex-nowrap align-items-center"
          }, [_c('span', {
            staticClass: "fw-700 font-medium-2"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.resolveFarePrice(_vm.resolveSelectedTrip.trips[tripIndex]).total)) + " ")]), _c('div', [_c('b-img', {
            staticClass: "m-50 rotate_class",
            style: {
              transform: _vm.rotateStylePrice(tripIndex)
            },
            attrs: {
              "blank-color": "#ccc",
              "src": require("@icons/arrow_warning.svg")
            }
          })], 1)]) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "text-airline font-weight-bolder"
    }, _vm._l(_vm.resolveFarePrice(_vm.resolveSelectedTrip.trips[tripIndex]).paxPrice, function (fareItem, fareIndex) {
      return _c('div', {
        key: fareIndex
      }, [fareItem.quantity ? _c('b-row', {
        staticClass: "mx-0 font-small-3 flex-nowrap fw-700 d-flex-between",
        attrs: {
          "no-gutters": ""
        }
      }, [(fareItem.fare || fareItem.surcharge + fareItem.tax > 0 || ['TH', 'AK'].includes(_vm.resolveSelectedTrip.trips[tripIndex].source)) && _vm.isVisiblePriceFareCol ? _c('b-col', {
        attrs: {
          "cols": "2",
          "lg": "2"
        }
      }, [_c('div', {
        staticClass: "d-flex-between"
      }, [_c('span', [_vm._v(_vm._s(fareItem.title))]), _c('span', [_vm._v("x" + _vm._s(fareItem.quantity))])])]) : _vm._e(), ['TH', 'AK'].includes(_vm.resolveSelectedTrip.trips[tripIndex].source) && (fareItem === null || fareItem === void 0 ? void 0 : fareItem.title) === 'INF' && (fareItem === null || fareItem === void 0 ? void 0 : fareItem.fare) === 0 ? _c('b-col', {
        staticClass: "d-flex-center justify-content-end",
        attrs: {
          "cols": "10",
          "lg": "10"
        }
      }, [_c('span', {
        staticClass: "text-danger text-nowrap font-small-2"
      }, [_vm._v("Vui lòng vào màn nhập thông tin để kiểm tra giá em bé")])]) : _vm._e(), _vm.isVisiblePriceFareCol && !(['TH', 'AK'].includes(_vm.resolveSelectedTrip.trips[tripIndex].source) && (fareItem === null || fareItem === void 0 ? void 0 : fareItem.title) === 'INF' && (fareItem === null || fareItem === void 0 ? void 0 : fareItem.fare) === 0) ? _c('b-col', {
        staticClass: "text-nowrap text-right",
        attrs: {
          "cols": "3",
          "md": "2",
          "lg": "3"
        }
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(fareItem.priceFareCol)) + " ")]) : _vm._e(), _vm.isVisibleFeeCol && !(['TH', 'AK'].includes(_vm.resolveSelectedTrip.trips[tripIndex].source) && (fareItem === null || fareItem === void 0 ? void 0 : fareItem.title) === 'INF' && (fareItem === null || fareItem === void 0 ? void 0 : fareItem.fare) === 0) ? _c('b-col', {
        staticClass: "text-nowrap text-right",
        attrs: {
          "cols": "3",
          "md": "2",
          "lg": "3"
        }
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(fareItem.priceFeeCol)) + " ")]) : _vm._e(), _vm.isVisibleTotalFareCol && !(['TH', 'AK'].includes(_vm.resolveSelectedTrip.trips[tripIndex].source) && (fareItem === null || fareItem === void 0 ? void 0 : fareItem.title) === 'INF' && (fareItem === null || fareItem === void 0 ? void 0 : fareItem.fare) === 0) ? _c('b-col', {
        staticClass: "text-warning text-nowrap text-right",
        attrs: {
          "cols": "3",
          "md": "2",
          "lg": "3"
        }
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(fareItem.priceTotalCol)) + " ")]) : _vm._e()], 1) : _vm._e()], 1);
    }), 0)])], 1) : _vm._e()]) : _c('i', {
      staticClass: "text-danger"
    }, [_vm._v(" " + _vm._s(_vm.$t('flight.noItiSelected')) + " ")])])], 1)], 1);
  }), _vm.isCombination ? _c('div', {
    staticClass: "mt-0 mb-50 px-75"
  }, [_c('div', {
    staticClass: "w-100 d-flex flex-nowrap align-items-center text-warning cursor-pointer ",
    staticStyle: {
      "max-width": "520px"
    },
    on: {
      "click": _vm.toggleVisiblePriceCombination
    }
  }, [_vm.isVisiblePriceFareCol ? _c('div', {
    staticClass: "d-flex flex-nowrap align-items-center"
  }, [_c('span', {
    staticClass: "fw-700 font-medium-2"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.resolveFarePrice(_vm.resolveSelectedTrip).total)) + " ")]), _c('div', [_c('b-img', {
    staticClass: "m-50 rotate_class",
    style: {
      transform: !_vm.isVisiblePriceCombination ? 'rotate(0deg)' : 'rotate(180deg)'
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@icons/arrow_warning.svg")
    }
  })], 1)]) : _vm._e()]), _c('b-collapse', {
    attrs: {
      "id": "priceItem-toggle-combination",
      "role": "tabpanel"
    },
    model: {
      value: _vm.isVisiblePriceCombination,
      callback: function callback($$v) {
        _vm.isVisiblePriceCombination = $$v;
      },
      expression: "isVisiblePriceCombination"
    }
  }, [_c('div', {
    staticClass: "text-airline font-weight-bolder"
  }, _vm._l(_vm.resolveFarePrice(_vm.resolveSelectedTrip).paxPrice, function (fareItem, fareIndex) {
    return _c('div', {
      key: fareIndex
    }, [fareItem.quantity ? _c('b-row', {
      staticClass: "mx-0 font-small-3 flex-nowrap fw-700 d-flex-between",
      attrs: {
        "no-gutters": ""
      }
    }, [(fareItem.fare || fareItem.surcharge + fareItem.tax > 0) && _vm.isVisiblePriceFareCol ? _c('b-col', {
      attrs: {
        "cols": "2",
        "lg": "2"
      }
    }, [_c('div', {
      staticClass: "d-flex-between"
    }, [_c('span', [_vm._v(_vm._s(fareItem.title))]), _c('span', [_vm._v("x" + _vm._s(fareItem.quantity))])])]) : _vm._e(), _vm.isVisiblePriceFareCol ? _c('b-col', {
      staticClass: "text-nowrap text-right",
      attrs: {
        "cols": "3",
        "md": "2",
        "lg": "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(fareItem.priceFareCol)) + " ")]) : _vm._e(), _vm.isVisibleFeeCol ? _c('b-col', {
      staticClass: "text-nowrap text-right",
      attrs: {
        "cols": "3",
        "md": "2",
        "lg": "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(fareItem.priceFeeCol)) + " ")]) : _vm._e(), _vm.isVisibleTotalFareCol ? _c('b-col', {
      staticClass: "text-warning text-nowrap text-right",
      attrs: {
        "cols": "3",
        "md": "2",
        "lg": "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(fareItem.priceTotalCol)) + " ")]) : _vm._e()], 1) : _vm._e()], 1);
  }), 0)])], 1) : _vm._e(), _vm.isVisiblePriceFareCol ? _c('div', {
    staticClass: "text-airline text-right mr-75 my-50"
  }, [_c('span', {
    staticClass: "mr-25 mr-md-75 text-nowrap font-weight-bold",
    class: _vm.isMobileView ? 'font-small-4' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.Total')) + " ")]), _c('span', {
    staticClass: "text-nowrap fw-800",
    class: _vm.isMobileView ? 'font-medium-1' : 'font-medium-4'
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPrice)) + " ")])]) : _vm._e(), _vm.isVN1A ? [_c('div', {
    staticClass: "mx-50 my-50 text-center border-danger rounded-lg p-50"
  }, [_c('div', {
    class: "ml-50 text-decoration-danger mr-25 text-danger mr-md-75 fw-700 ".concat(_vm.isMobileView ? 'font-small-4' : '')
  }, [_vm._v(" Do lỗi hệ thống VN nên vui lòng tính lại giá trước khi báo cho khách. ")]), _c('BButton', {
    attrs: {
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.handleReCalculatePrice();
      }
    }
  }, [_vm._v(" Tính giá ")])], 1)] : _vm._e(), _vm.isVN1A ? _c('CalculateTripPriceModalVN1A', {
    attrs: {
      "selected-trip": _vm.draftSelectedTrip.filter(function (trip) {
        return !!trip && ['VN1A'].includes(trip.source) && !['INTERNAL'].includes(trip.clientId);
      })
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }